'use client';

import React, { useEffect, useState } from 'react';

import { ModalContent, ModalBody, ModalHeader } from '@nextui-org/react';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { signIn, SignInResponse, useSession } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CommonModal from '@/app/components/CommonModal';
import Input from '@/app/components/Input';
import { emailChecker, emailValidation } from '@/helpers/validations';
import { showLoading, hideLoading } from '@/store/loading';

import { navigations } from '../constants';

const Login = () => {
  const { t } = useTranslation();
  const history = useRouter();
  const { data: session } =useSession();
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (session?.user?.terms_accepted_on && session?.user?.isVerified) {
      history.push(navigations.AI_ANAYTICS);
    }

    if (session?.user?.newDevice) {
      history.push(navigations.CONFIRM_DEVICE);
    }

    if (!session?.user?.isVerified && session?.user?.verificationToken) {
      history.push(`${navigations.CONFIRM_EMAIL}?token=${session.user.verificationToken}`);
    }
  }, [session]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    dispatch(showLoading());
    try {
      const signInResponse= (
        await signIn('credentials', { ...data, device: getCookie('device'), redirect: false })
      ) as SignInResponse;
      if (signInResponse.ok) {
        history.push(navigations.AI_ANAYTICS);
      } else {
        setError('Invalid credentials.');
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const onGoogleSignIn = async () => {
    dispatch(showLoading());
    try {
      await signIn('google', { callbackUrl: navigations.AI_ANAYTICS });
    } finally {
      dispatch(hideLoading());
    }
  };

  const onAppleSignIn = async () => {
    dispatch(showLoading());
    try {
      await signIn('apple', { callbackUrl: navigations.AI_ANAYTICS });
    }  finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    setError('');
  }, [JSON.stringify(watch())]);

  return (
    <CommonModal onESC={() => history.push(navigations.AI_ANAYTICS)} isOpen={true} closeButton={<></>}>
      <ModalContent>
        <ModalHeader>
          <Link
            href={navigations.AI_ANAYTICS}
            className='ml-auto'
          >
            <div className='text-gray-400 bg-transparent hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg w-8 h-8 inline-flex justify-center items-center '>
              <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'/>
              </svg>
            </div>
          </Link>
        </ModalHeader>
        <ModalBody>
          <div className='w-full max-w-md mx-auto text-center pb-8 flex-col flex items-center'>
            <h3 className='text-2xl font-bold pb-3'>
              <Trans i18nKey='Sign In' />
            </h3>
          </div>
          <form
            className='flex flex-col space-y-4'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              name='email'
              type='email'
              placeholder={t('Email Address')}
              register={register('email', {
                ...emailValidation,
                required: t('Please enter your email address') as string,
                validate: (value) =>
                  emailChecker(value) ||
                  (t('Please enter valid email address') as string),
              })}
              error={errors.email}
            />
            <Input
              name='password'
              type='password'
              placeholder={t('Password')}
              error={errors.password}
              register={register('password', {
                required: t('Please enter your password') as string,
              })}
            />
            <Link
              href={navigations.FORGET_PASSWORD}
              // children='Forgot Password?'
              className='ml-auto text-sm font-bold text-right w-max'
            >Forget Password?</Link>
            {/* <div className='flex justify-center align-middle'>
              <GoogleReCaptcha
                onVerify={(token: string) => {
                  setFormValid(!!token);
                  setShowErrors(false);
                }}
              />
            </div> */}
            {/* {showErrors && (
              <div className='flex-1'>
                <p className='mt-1 text-xs text-center text-red-500'>
                  {t('Please check the reCATPTCHA')}
                </p>
              </div>
            )} */}
            {error && (
              <div className='flex-1'>
                <p className='mt-1 text-xs text-center text-red-500'>
                  {error}
                </p>
              </div>
            )}
            <div className='flex justify-center'>
              <button
                className="flex justify-center items-center go-btn bg-blue-500 hover:bg-blue-700 text-white p-2.5 min-w-[130px] rounded-[30px]"
                type="submit"
              >
                <p className='text-[16px]'> SIGN IN &gt;</p>
              </button>
            </div>
          </form>
          <p className='pt-5 pb-2 text-sm text-center'>
            Don&apos;t have an Account?{' '}
            <Link
              href={navigations.REGISTER}
              className='font-bold'
            >Start Free Trial Here</Link>
          </p>
          <div className="block sm:flex my-3 justify-between">
            <div className="cursor-pointer flex items-center rounded-full bg-white pl-1 pr-2" onClick={onGoogleSignIn}>
              <img src="/google.webp" alt="google icon" width={30}/>
              <span className="text-black pl-1 text-small">Continue with Google</span>
            </div>
            <div className="cursor-pointer flex items-center rounded-full bg-white pl-2 pr-2 mt-3 sm:mt-0 py-1" onClick={onAppleSignIn}>
              <img src="/apple.png" alt="google icon" width={20}/>
              <span className="text-black pl-2.5 text-small">Continue with Apple</span>
            </div>
          </div>
          {/* <p className='text-sm text-center text-gray-400'>
            {'This site is protected by reCAPTCHA and the Google '}
            <a
              href="https://policies.google.com/privacy"
              className='cursor-pointer'
            >
              Privacy Policy
            </a>
            {' and '}
            <a
              href="https://policies.google.com/terms"
              className=' cursor-pointer'
            >
              Terms of Service
            </a>{' '}
            apply.
          </p> */}
        </ModalBody>
      </ModalContent>
    </CommonModal>
  );
};

export default Login;
